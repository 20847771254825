body {
  margin: 0;
}

.App {
  font-family: sans-serif;
  text-align: center;
  height: 100vh;
}

.blockly-editor {
  height: 70vh;
}

.table-container {
  overflow: auto;
  max-height: 30vh;
}

html,
body {
  cursor: grab;
}
